@tailwind base;
@tailwind components;
@tailwind utilities;

@import './fonts.css';
@import './base.css';

/*****************************
/*Components*/

@import './Components/Navbar/Navbar.css';


