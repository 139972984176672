@import url('https://fonts.googleapis.com/css2?family=Readex+Pro:wght@160..700&display=swap');

.readex-pro-bold {
  font-family: "Readex Pro", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  font-variation-settings: "HEXP" 0;
}

.readex-pro-medium {
  font-family: "Readex Pro", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  font-variation-settings: "HEXP" 0;
}

.readex-pro-regular {
  font-family: "Readex Pro", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings: "HEXP" 0;
}

.readex-pro-light {
  font-family: "Readex Pro", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  font-variation-settings: "HEXP" 0;
}

.readex-pro-thin {
  font-family: "Readex Pro", sans-serif;
  font-optical-sizing: auto;
  font-weight: 100;
  font-style: normal;
  font-variation-settings: "HEXP" 0;
}

.readex-pro-extralight {
  font-family: "Readex Pro", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
  font-variation-settings: "HEXP" 0;
}

.readex-pro-black {
  font-family: "Readex Pro", sans-serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: normal;
  font-variation-settings: "HEXP" 0;
}

.readex-pro-extrabold {
  font-family: "Readex Pro", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
  font-variation-settings: "HEXP" 0;
}

.readex-pro-semibold {
  font-family: "Readex Pro", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  font-variation-settings: "HEXP" 0;
}

.readex-pro-thinitalic {
  font-family: "Readex Pro", sans-serif;
  font-optical-sizing: auto;
  font-weight: 100;
  font-style: italic;
  font-variation-settings: "HEXP" 0;
}

.readex-pro-lightitalic {
  font-family: "Readex Pro", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: italic;
  font-variation-settings: "HEXP" 0;
}

.readex-pro-regularitalic {
  font-family: "Readex Pro", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: italic;
  font-variation-settings: "HEXP" 0;
}

.readex-pro-mediumitalic {
  font-family: "Readex Pro", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: italic;
  font-variation-settings: "HEXP" 0;
}

.readex-pro-semibolditalic {
  font-family: "Readex Pro", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: italic;
  font-variation-settings: "HEXP" 0;
}

.readex-pro-bolditalic {
  font-family: "Readex Pro", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: italic;
  font-variation-settings: "HEXP" 0;
}

.readex-pro-blackitalic {
  font-family: "Readex Pro", sans-serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: italic;
  font-variation-settings: "HEXP" 0;
}

.readex-pro-extrabolditalic {
  font-family: "Readex Pro", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: italic;
  font-variation-settings: "HEXP" 0;
}
